import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Features</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Bug Fixes</AnchorLink>
    </AnchorLinks>
    <h2>{`Release v2021.7.16`}</h2>
    <h3>{`Features`}</h3>
    <p><strong parentName="p">{`Each step shows Execution Start Time and End Time`}</strong></p>
    <p>{`Every step executed in the deployment details board shows the start time and end time when the step succeed.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "47.56944444444444%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABDUlEQVQoz5WQ3U7CQBCF9/1fxYcwXFhJi9EIoaCkUS8gpZJK96c7f7umXSHGCwnf1ZnMTM6ZUR/bZlsf9ocvbUyntdHmf7Q24N36vb65Xai2HdaOXdceO2Nt7xwA+BH4xZ8SEQhBefBNs6/r+vPQ9h5FhJnlBJ84ayJC/BlTiOicE5EYI7GEEETEOQcemDmMMDMAJC0yzHjvtdYKAXTXHUfoxBgMiShZJbezc0purVVDA6DveyKMMaTzQghElKwA4OyWhLU2dYfYLMISOMSrGJZF+LV6m86eisfF82K5Xq2WlyjLcj6f73Y7JSKbzcv0PpsV+cOsyPP87hJZlk0mk6qqxm+DaIgGI4XrYn8DYYo15nR17MwAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Start End Time",
        "title": "Start End Time",
        "src": "/static/30c8636862ddbd3c5fcb123ff7e57378/3cbba/start-time-end-time-feature.png",
        "srcSet": ["/static/30c8636862ddbd3c5fcb123ff7e57378/7fc1e/start-time-end-time-feature.png 288w", "/static/30c8636862ddbd3c5fcb123ff7e57378/a5df1/start-time-end-time-feature.png 576w", "/static/30c8636862ddbd3c5fcb123ff7e57378/3cbba/start-time-end-time-feature.png 1152w", "/static/30c8636862ddbd3c5fcb123ff7e57378/0b124/start-time-end-time-feature.png 1728w", "/static/30c8636862ddbd3c5fcb123ff7e57378/7bd75/start-time-end-time-feature.png 1842w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p><strong parentName="p">{`Cancel Step Execution - Provision Portal deployments`}</strong></p>
    <p>{`Adding a button that allows the users stop the step execution and mark this step as `}<strong parentName="p"><em parentName="strong">{`Cancelled`}</em></strong></p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "48.958333333333336%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABFUlEQVQoz41R205DQQg8//89foPf4JvGxKhtbbs9C8sd3UNsavTBeQICMwMsT4/PL++Ht/3x3NbeYV3X/j/c3T8sr2+71i7H03m3PxxPZ0QcY9AvMPNtzMwRtvTemfnj8NFaWzuKaISbmbtHhP8FEdEJW1SVmQDAzGbNLCJEpEiLxd2ZWVUrrmJrbZEJBpjbAkBEbAyFH91XO0UkIkt5IKIxhm2yRLSJhKhl5nQkkpkiQkTuTjSdZuQcJp5V6B0RizszY3N/XTsizGyMUbIIaOZzGElWGGMgAMg2kJlTAbECd89MVa1DiMjlsrpvwzr9jPrQXHTbWURX5Eq/K4KI9SoAVNU6mNQbi/UGP1LemurJRPR1sE8cI0bsSuhZXgAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Cancel button",
        "title": "Cancel button",
        "src": "/static/90debdeed941efd6ce09deedfe905743/3cbba/cancel-step-button.png",
        "srcSet": ["/static/90debdeed941efd6ce09deedfe905743/7fc1e/cancel-step-button.png 288w", "/static/90debdeed941efd6ce09deedfe905743/a5df1/cancel-step-button.png 576w", "/static/90debdeed941efd6ce09deedfe905743/3cbba/cancel-step-button.png 1152w", "/static/90debdeed941efd6ce09deedfe905743/0b124/cancel-step-button.png 1728w", "/static/90debdeed941efd6ce09deedfe905743/4f321/cancel-step-button.png 1851w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <h3>{`Bug Fixes`}</h3>
    <p><em parentName="p">{`Bugs resolved in this release were found during development:`}</em></p>
    <p><strong parentName="p">{`Step execution result list only contains the first 50 items`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://dev.azure.com/BD-MMS-DevOps/Provision%20Portal/_workitems/edit/662"
        }}>{`Bug 662`}</a>{`: Step execution result list only contains the first 50 items`}</p>
    </blockquote>
    <p><em parentName="p">{`Resloution:`}</em>{` change the approach to evaluate `}<strong parentName="p">{`StepExecutionTruncated`}</strong>{` property in order to retrieve the complete list of steps from `}<strong parentName="p">{`DescribeAutomationStepExecutions`}</strong>{`.`}</p>
    <p><strong parentName="p">{`Showing invalid date for Steps that don’t run`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://dev.azure.com/BD-MMS-DevOps/Provision%20Portal/_workitems/edit/676"
        }}>{`Bug 676`}</a>{`: Start Time and End Time for steps that don’t run show invalid date`}</p>
    </blockquote>
    <p><em parentName="p">{`Resloution:`}</em>{` For the steps that are skipped and have no Start Time and End Time it will not display any of those values, if the overall status is succeed, then the steps will show `}<strong parentName="p"><em parentName="strong">{`Not Required`}</em></strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      